@font-face {
  font-family: 'ogg';
  src: url('/fonts/ogg/ogg-roman-webfont.woff2') format('woff2'),
    url('/fonts/ogg/ogg-roman-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

$ease--out-editorial: cubic-bezier(0.645, 0.045, 0.355, 1);
$font-face--display: 'ogg', -apple-system, BlinkMacSystemFont, 'Segoe UI',
  Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

body {
  min-height: 100vh;
  background-image: linear-gradient(
    to bottom,
    hsl(194, 20%, 24%),
    hsl(191, 21%, 16%)
  );
  display: grid;
  place-items: center;
  font-family: $font-face--display;
}
canvas {
  position: absolute;
  z-index: -1;
}
a {
  color: inherit;
  text-decoration: none;
}
h1 {
  font-size: 16vmin;
  text-transform: lowercase;
  cursor: pointer;
  a {
    position: relative;
    color: transparent;

    &:before,
    &:after {
      color: hsl(194, 22%, 24%);
      content: attr(data-title);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
    &:before {
      clip-path: polygon(0 0, 100% 0, 100% 50%, 0 50%);
    }
    &:after {
      clip-path: polygon(0 50%, 100% 50%, 100% 100%, 0 100%);
      transition: transform 0.8s $ease--out-editorial;
    }
    &:hover {
      &:after {
        transform: translateX(0.3ch);
      }
    }
  }
}
